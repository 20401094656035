import React, {
  Fragment,
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CreatePortal from "../CreatePortal/CreatePortal";
import Modal from "../Modal/Modal";
import ModalHeader from "../Modal/ModalHeader";
import ModalFooter from "../Modal/ModalFooter";
import ScrollBar from "react-perfect-scrollbar";
import { functionOptionType } from "../CustomDropdown/CustomDropdown";
import { useBeforeUnload, useBlocker } from "react-router-dom";
import { TbAlertOctagonFilled } from "react-icons/tb";
import Button from "../Button/Button";
import useFullScreen from "../../../common/hooks/useFullScreen";

type typeProps = {
  close: () => void;
  title?: string;
  lightTitle?: string;
  actionButton?: {
    text?: string;
    processing?: boolean;
    disabled?: boolean;
    onSubmit?: () => void;
    className?: string;
    submitIco?: any;
  };
  children?: ReactNode;
  modalClassName?: string;
  dropdownOptions?: functionOptionType[];
  dropdownChildren?: ReactNode;
  headerClassName?: string;
  headerChildren?: ReactNode;
  closeButtonText?: string;
  closeFooter?: () => void;
  additionalButton?: any;
  isLoading?: boolean;
  enableFullScreen?: boolean;
  setContentHeight?: (value: number) => void;
  contentClassName?: string;
  additionalButtonChildren?: ReactNode;
  hideModalFooter?: boolean;
  hideModalHeader?: boolean;
  disableHorizontalScroll?: boolean;
  childrenClassName?: string;
  enableSaveChanges?: {
    hasChanges?: boolean;
  };
  discardHasChanges?: () => void;
  overflowAuto?: boolean;
  hideCloseButton?: boolean;
  hideCloseIco?: boolean;
  recalculateHeight?: any;
  modalContainerClassName?: string;
};
const ModalWithContent: FunctionComponent<typeProps> = (props) => {
  const {
    close,
    title,
    actionButton,
    children,
    modalClassName,
    lightTitle,
    dropdownOptions,
    dropdownChildren,
    headerClassName,
    headerChildren,
    closeButtonText,
    closeFooter,
    additionalButton,
    isLoading,
    enableFullScreen,
    setContentHeight,
    contentClassName,
    additionalButtonChildren,
    hideModalFooter = false,
    hideModalHeader = false,
    disableHorizontalScroll = false,
    childrenClassName,
    enableSaveChanges,
    discardHasChanges,
    overflowAuto,
    hideCloseIco,
    hideCloseButton,
    recalculateHeight, // The dependency for recalculating the height of the elements. If that prop changes, it recalculates the height.
    modalContainerClassName,
  } = props;

  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [modalHeight, setModalHeight] = useState<number>(0);
  const [footerHeight, setFooterHeight] = useState<number>(0);
  const [childrenHeight, setChildrenHeight] = useState<number>(0);
  const footerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const { isFullScreenOpen, toggleFullScreen } = useFullScreen();

  useEffect(() => {
    const updateHeight = () => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.clientHeight);
      }
      if (modalRef?.current) {
        setModalHeight(modalRef?.current.clientHeight);
      }
      if (footerRef?.current) {
        setFooterHeight(footerRef?.current.clientHeight);
      }
      if (childrenRef?.current) {
        setChildrenHeight(childrenRef?.current.clientHeight);
      }
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [isLoading, isFullScreenOpen, recalculateHeight]);

  const heightContent = useMemo(() => {
    if (
      modalHeight > 0 &&
      ((headerHeight > 0 && !hideModalHeader) || hideModalHeader) &&
      ((footerHeight > 0 && !hideModalFooter) || hideModalFooter)
    ) {
      return (
        (!isFullScreenOpen ? (modalHeight * 90) / 100 : modalHeight) -
        headerHeight -
        footerHeight
      );
    }
  }, [modalHeight, headerHeight, footerHeight]);

  useEffect(() => {
    if (setContentHeight && heightContent) {
      setContentHeight(heightContent);
    }
  }, [heightContent]);

  const handleCloseFullScreen = () => {
    if (enableFullScreen && isFullScreenOpen) {
      toggleFullScreen();
    }
  };

  const handleCloseModal = (type?: "outside" | "header" | "footer") => {
    handleCloseFullScreen();
    if (enableSaveChanges?.hasChanges) {
      setUnsavedChangesModal({ show: true, proceed: null });
    } else {
      if (type === "header" || type === "outside") {
        return close();
      } else if (type === "footer") {
        return closeFooter ? closeFooter() : close();
      }
    }
  };

  // UNSAVED CHANGES
  const [unsavedChangesModal, setUnsavedChangesModal] = useState<{
    show: boolean;
    proceed: (() => void) | null;
    type?: string | null;
  }>({
    show: false,
    proceed: null,
    type: null,
  });

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !!enableSaveChanges?.hasChanges &&
      currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (blocker.state === "blocked" && !!enableSaveChanges?.hasChanges) {
      setUnsavedChangesModal({
        show: true,
        proceed: blocker.proceed,
        type: null,
      });
    }
  }, [blocker.state, blocker.location?.key]);

  useBeforeUnload(
    useCallback(
      (e) => {
        if (!!enableSaveChanges?.hasChanges) {
          e.preventDefault();
          setUnsavedChangesModal({ show: true, proceed: null, type: null });
        }
      },
      [!!enableSaveChanges?.hasChanges]
    )
  );

  const handleDiscardChanges = () => {
    if (unsavedChangesModal?.type === "additional") {
      additionalButton?.onSubmit();
    } else {
      close();
    }
  };

  const handleCheckPreviewForm = () => {
    if (unsavedChangesModal?.type === "additional") {
      additionalButton?.onSubmit();
    }
    if (actionButton?.onSubmit) {
      actionButton?.onSubmit();
    }
    if (isFullScreenOpen) {
      toggleFullScreen();
    }
  };

  return (
    <Fragment>
      <CreatePortal>
        <Modal
          overflow="unset"
          childrenClassName={`p-[0px] ${modalClassName || ""} ${overflowAuto ? "" : "!overflow-hidden"} !max-w-[90%] !max-h-[90%]`}
          styleChildren={{
            minHeight: `${headerHeight + footerHeight}px`,
          }}
          close={() => handleCloseModal("outside")}
          customRef={modalRef}
          containerClassName={modalContainerClassName || ""}
        >
          {!hideModalHeader ? (
            <div className={"fixed top-0 w-full"} ref={headerRef}>
              <ModalHeader
                close={() => handleCloseModal("header")}
                className={`w-full ${headerClassName || ""}`}
                dropdownOptions={dropdownOptions}
                dropdownChildren={dropdownChildren}
                enableFullScreen={enableFullScreen}
                hideCloseIco={hideCloseIco}
              >
                {headerChildren ? (
                  headerChildren
                ) : (
                  <div className={"flex flex-col"}>
                    <h1 className={"text-lg font-[500] mt-[2px]"}>{title}</h1>
                    {lightTitle ? (
                      <span className={"text-base font-[300]"}>
                        {lightTitle}
                      </span>
                    ) : null}
                  </div>
                )}
              </ModalHeader>
            </div>
          ) : null}
          <ScrollBar
            id="scrollBarContainerBox"
            component="div"
            style={{
              marginTop: childrenHeight === 0 ? "" : `${headerHeight}px`,
              marginBottom: childrenHeight === 0 ? "" : `${footerHeight}px`,
              maxHeight: `${heightContent}px`,
            }}
            className={`p-[20px] ${contentClassName || ""}`}
            options={{ suppressScrollX: disableHorizontalScroll || false }}
          >
            <div
              className={`h-full ${childrenClassName || ""}`}
              ref={childrenRef}
            >
              {children}
            </div>
          </ScrollBar>
          {!hideModalFooter ? (
            <ModalFooter
              customRef={footerRef}
              containerClassName={"fixed bottom-0 w-full"}
              actionButton={actionButton}
              close={() => handleCloseModal("footer")}
              closeButtonText={closeButtonText}
              hideCloseButton={hideCloseButton}
              additionalButton={additionalButton}
              additionalButtonChildren={additionalButtonChildren}
              additionaButtonEnableSaveChanges={{
                hasChanges: enableSaveChanges?.hasChanges,
                show: () =>
                  setUnsavedChangesModal({
                    show: true,
                    proceed: null,
                    type: "additional",
                  }),
              }}
            />
          ) : null}
        </Modal>
      </CreatePortal>
      {unsavedChangesModal.show && (
        <CreatePortal>
          <Modal
            close={() =>
              setUnsavedChangesModal({ show: false, proceed: null, type: null })
            }
          >
            <div className="flex flex-col items-center gap-5 w-[400px]">
              <div className="p-2 bg-z-grey-100 rounded-full w-fit">
                <TbAlertOctagonFilled className="text-invalid-red text-[50px]" />
              </div>
              <h2 className="text-2xl font-medium">Unsaved Changes</h2>
              <h2 className="text-base font-light">
                Oops, you forgot to save your changes
              </h2>
              <div className="flex gap-3 justify-between w-full">
                <Button
                  type="Secondary"
                  text={`Discard and continue`}
                  className="flex-1 mt-2 rounded-xl"
                  onClick={() => {
                    setUnsavedChangesModal({
                      show: false,
                      proceed: null,
                      type: null,
                    });
                    handleDiscardChanges();
                    isFullScreenOpen && toggleFullScreen();
                    discardHasChanges && discardHasChanges();
                    return unsavedChangesModal.proceed
                      ? unsavedChangesModal.proceed()
                      : null;
                  }}
                />
                <Button
                  type="Primary"
                  text={`Save and continue`}
                  className="flex-1 mt-2 rounded-xl"
                  onClick={() => {
                    handleCheckPreviewForm();
                  }}
                  disabled={actionButton?.disabled}
                  state={actionButton?.processing ? "Loading" : ""}
                />
              </div>
              <Button
                type="Naked"
                text="Cancel"
                className="w-1/2 mt-2 rounded-xl !font-medium"
                onClick={() =>
                  setUnsavedChangesModal({
                    show: false,
                    proceed: null,
                    type: null,
                  })
                }
              />
            </div>
          </Modal>
        </CreatePortal>
      )}
    </Fragment>
  );
};

export default ModalWithContent;
