export enum EmailTypeEnum {
  APPLICATION_CREATED_FOR_POSITION = "APPLICATION_CREATED_FOR_POSITION",
  APPLICATION_CREATED_FOR_POSITION_AUTOMATIC_PROCEED = "APPLICATION_CREATED_FOR_POSITION_AUTOMATIC_PROCEED",
  APPLICATION_CREATED_FOR_TEST_SIMULATION = "APPLICATION_CREATED_FOR_TEST_SIMULATION",
  APPLICANT_HIRED = "APPLICANT_HIRED ",
  APPLICANT_REJECTED = "APPLICANT_REJECTED",
  APPLICATION_STEP_RESTART = "APPLICATION_STEP_RESTART",
  APPLICATION_STEP_ACCESS = "APPLICATION_STEP_ACCESS",
  APPLICATION_REMINDER_NOT_STARTED = "APPLICATION_REMINDER_NOT_STARTED",
  APPLICATION_REMINDER_NOT_COMPLETED = "APPLICATION_REMINDER_NOT_COMPLETED",
  APPLICATION_STEP_ACCESS_LIVEINTERVIEW = "APPLICATION_STEP_ACCESS_LIVEINTERVIEW",
  COMPLETE_APPLICATION_FORM = "COMPLETE_APPLICATION_FORM",
}
