import { ReactComponent as FullScreenIcon } from "assets/icons/full-screen-ico.svg";
import { ReactComponent as ExitFullScreenIcon } from "assets/icons/full-screen-2-ico.svg";
import useFullScreen from "../../../common/hooks/useFullScreen";
import { FunctionComponent, useMemo } from "react";

const isIosDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
};

type typeProps = {
  containerClassName?: string;
};
const FullScreen: FunctionComponent<typeProps> = (props) => {
  const { containerClassName } = props;
  const { isFullScreenOpen, toggleFullScreen } = useFullScreen();
  const RenderIcon = useMemo(() => {
    return isFullScreenOpen ? ExitFullScreenIcon : FullScreenIcon;
  }, [isFullScreenOpen]);

  if (isIosDevice()) {
    return null;
  }

  return (
    <div
      className={`w-[32px] h-[32px] flex justify-center items-center rounded-lg cursor-pointer hover:bg-z-grey-100 group z-10 ${containerClassName || ""}`}
      onClick={() => toggleFullScreen()}
    >
      <RenderIcon
        className={
          "cursor-pointer group-hover:[&_path]:fill-main-color [&_path]:fill-z-grey-500"
        }
      />
    </div>
  );
};

export default FullScreen;
