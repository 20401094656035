import { ReactComponent as UpStairsIco } from "assets/icons/upstairs-ico.svg";
import { ReactComponent as DocIco } from "assets/icons/document-ico-blue.svg";
import { ReactComponent as PlusIco } from "assets/icons/plus-circle-ico.svg";
import { ReactComponent as DocumentIco } from "assets/icons/document-ico-orange.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right-white.svg";
import { ReactComponent as ArrowBigRight } from "assets/icons/big-arrow-right.svg";
import { ReactComponent as LiveInterviewIco } from "assets/icons/avatar-purple-ico.svg";

import { useAuthorization } from "common/hooks/useAuthorization";
import { getRandomId } from "common/utility/Utils";
import {
  getHumanizedApplicationState,
  renderModuleName,
  renderTextStateColor,
} from "common/helpers/functions.helpers";
import ScrollBarWrapperX from "views/components/ScrollWrapper/ScrollBarWrapperX";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { useEffect, useState } from "react";

const scrollbarId = getRandomId();

const HiringFlowSteps = ({
  selected,
  steps,
  containerClassName,
  viewOnly,
  loading,
  onCreateModule,
  onSelectModule,
}: {
  selected: number | null;
  steps: any[];
  containerClassName?: string;
  viewOnly?: boolean;
  loading?: boolean;
  onCreateModule?: (moduleType: string, index: number) => void;
  onSelectModule?: (module: any, index: number) => void;
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(
    selected || null
  );
  const { checkPermissions } = useAuthorization();

  function handleSelectStep(index: number) {
    // handle Select Step function
    setSelectedIndex(index);
    if (onSelectModule) {
      onSelectModule(steps[index], index);
    }
  }

  useEffect(() => {
    // listen to the selected prop and update the selectedIndex
    setSelectedIndex(selected);
  }, [selected]);

  const createModule = (moduleType: string, index: number) => {
    if (onCreateModule) {
      onCreateModule(moduleType, index);
    }
  };

  const dropdownOptions = (index: number) => {
    return [
      {
        Icon: DocumentIco,
        label: "Assessment Module",
        functionOption: () => createModule("assesment", index),
      },
      checkPermissions("GET_MICRODIMENSIONS")
        ? {
            Icon: LiveInterviewIco,
            label: "Live Interview Module",
            functionOption: () => createModule("live_interview", index),
          }
        : null,
    ];
  };

  const renderDropdowns = (index: number) => {
    return dropdownOptions(index)?.filter((item) => item !== null) || [];
  };

  const renderAddIco = (addIcon: boolean, hideArrow = false, index: number) => {
    return addIcon ? (
      <div className={"flex justify-center items-center"}>
        <div className={"absolute left-[7px]"}>
          <CustomDropdown
            permissions={["UPDATE_POSITION_HIRING_FLOW", "GET_POSITIONS"]}
            noPermissionsText={
              "You don't have permission to update hiring flow"
            }
            menuClassName={"!py-2 !px-0"}
            functionOption={renderDropdowns(index) as any}
            IconDropdown={PlusIco}
            classNameContainer={"w-[30px]"}
            // processing={positionCreateStep.loading || position.refreshLoading}
            processing={loading}
          />
        </div>
        <ArrowBigRight className={"w-[60px]"} />
      </div>
    ) : !hideArrow ? (
      <ArrowRight />
    ) : // <ArrowIco />
    null;
  };

  const renderIcoType = (module: string) => {
    switch (module) {
      case "auth":
        return <UpStairsIco />;
      case "live_interview":
        return <LiveInterviewIco />;
      case "assesment":
        return <DocIco className={"[&_path]:fill-[#F6AF45]"} />;
    }
  };

  return (
    <div
      className={`bg-[#E7E7E7] w-full z-10 border-t border-b border-[#D6D6D6] ${containerClassName ? containerClassName : ""}`}
    >
      <ScrollBarWrapperX id={scrollbarId} className={"w-full m-auto"}>
        <div
          className={`w-full mx-auto px-[30px] py-[12px] flex gap-[10px] items-center justify-start`}
        >
          {steps?.length
            ? steps.map((step: any, i: number) => {
                return (
                  <div
                    key={i}
                    className="flex gap-[10px] items-center select-none last:pr-[30px]"
                  >
                    <div
                      className={`bg-white cursor-pointer rounded-[10px] flex w-max group ${
                        selectedIndex === i
                          ? "outline outline-2 outline-main-color"
                          : "hover:outline outline-2 outline-[#C5C5C5]"
                      }`}
                      onClick={() => handleSelectStep(i)}
                    >
                      <div
                        className={` ${
                          selectedIndex === i ? "text-main-color" : ""
                        } group-hover:text-main-color flex justify-center items-center text-[#B3B3B3] text-lg font-[500] px-3.5 border-r-[2px] border-[#E7E7E7] py-[5px]`}
                      >
                        {i + 1}
                      </div>
                      <div
                        className={
                          "flex justify-start items-center gap-2 px-3.5 py-[5px]"
                        }
                      >
                        {renderIcoType(step.moduleType)}
                        <div>
                          <p
                            className={`${
                              selectedIndex === i
                                ? "text-main-color"
                                : "text-[#8C8C8C]"
                            } group-hover:text-main-color text-sm font-[500] mt-[1px]`}
                          >
                            {renderModuleName(step.moduleName)}
                          </p>
                          {step?.moduleState ? (
                            <p
                              className={`${renderTextStateColor(
                                step.moduleState
                              )} text-xs font-medium`}
                            >
                              {getHumanizedApplicationState(step.moduleState)}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {step?.info ? <>{step.info}</> : <></>}
                    </div>
                    <div
                      className={
                        "relative z-[5] flex justify-center items-center"
                      }
                    >
                      {renderAddIco(!viewOnly, i + 1 === steps.length, i)}
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </ScrollBarWrapperX>
    </div>
  );
};

export default HiringFlowSteps;
