import { ChangeEvent, useEffect, useState } from "react";

type propsTypes = {
  label?: string;
  value?: string;
  name?: string;
  placeholder?: string;
  className?: string;
  invalid?: boolean;
  invalidMessage?: string;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  maxChar?: number;
  isAboveMaxChar?: (proof: boolean) => void;
  icon?: string;
  onKeyPress?: (value: React.KeyboardEvent<HTMLInputElement>) => void;
  autocomplete?: "off" | "on";
  preFilled?: boolean;
};

const TextInput = (props: propsTypes) => {
  const [inputValue, setInputValue] = useState("");
  const [localError, setLocalError] = useState(false);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
    props.isAboveMaxChar &&
      props.maxChar &&
      props.isAboveMaxChar(e.target.value.length > props.maxChar);
  };

  useEffect(() => {
    if (props.value) {
      setInputValue(props.value);
    } else {
      setInputValue("");
    }
  }, [props.value]);

  useEffect(() => {
    if (props.maxChar) {
      if (inputValue.length > props.maxChar) {
        setLocalError(true);
      } else {
        setLocalError(false);
      }
    }
  }, [inputValue.length]);

  return (
    <div className="flex flex-col w-full">
      {props.label ? (
        <label
          htmlFor={props.name || "text-input"}
          className="text-z-grey-450 text-sm font-light mb-1.5 select-none"
        >
          {props.label}
        </label>
      ) : (
        ""
      )}
      <div
        className={`relative w-full ${
          props.icon ? "flex justify-center items-center" : ""
        }`}
      >
        <input
          type="text"
          name={props.name || "text-input"}
          value={inputValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeHandler(e)}
          placeholder={props.placeholder}
          disabled={props.disabled}
          className={`
               ${props.className} w-full p-2.5 pl-3.5 text-base font-normal
               ${
                 props.invalid || localError
                   ? "border border-invalid-red bg-invalid-dimmed-red"
                   : props.icon
                     ? "border-b border-t border-l"
                     : props.preFilled
                       ? "border border-main-color bg-main-color-light text-main-color"
                       : "border border-z-grey-450"
               }
               ${props.icon ? "rounded-l-xl" : "rounded-xl"}
               focus:outline-none disabled:cursor-not-allowed disabled:text-z-grey-600 disabled:bg-z-grey-100 disabled:border-z-grey-450
            `}
          maxLength={props.maxChar}
          onKeyPress={(e) => (props.onKeyPress ? props.onKeyPress(e) : null)}
          autoComplete={props.autocomplete}
        />

        {props.icon ? (
          <div
            className={`w-[47px] h-full rounded-r-xl border-t border-b border-r flex justify-center items-center border-z-grey-450`}
          >
            <img src={props.icon} alt="input-ico" />
          </div>
        ) : null}
      </div>
      {props.maxChar && (
        <div
          className={`${
            localError || props.invalid ? "text-invalid-red" : "text-z-grey-600"
          } px-1.5 tracking-wider py-[2px] text-right right-2 text-[10px] rounded-b-md`}
        >
          {inputValue.length}/{props.maxChar}
        </div>
      )}
      {(props.invalid && props.invalidMessage) || localError ? (
        <p className="text-invalid-red text-sm font-light mt-1.5">
          {localError
            ? "Max 50 characters is allowed"
            : props.invalidMessage
              ? `${props.invalidMessage}`
              : ""}
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export default TextInput;
