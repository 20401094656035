import React, { useState } from "react";
import UploadDropzone from "views/components/UploadInput/UploadDropzone";
import { ReactComponent as CsvIco } from "assets/icons/csv-ico.svg";
import { useParams } from "react-router";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/reduxTypes";
import {
  downloadPositionTemplate,
  importPositionTemplate,
} from "../../reducer/position.actions";
import Spinner from "../../../../components/spinners/Spinner";
import ModalWithContent from "../../../../components/ModalWithContent/ModalWithContent";

type inputType = {
  error?: boolean;
};

const initialInput: inputType = {
  error: false,
};

const ImportApplicantsForm = ({
  close,
  refreshFunction,
}: {
  close?: () => void;
  refreshFunction?: any;
}) => {
  const [input, setInput] = useState<inputType>(initialInput);
  const [file, setFile] = useState<File | null>(null);
  const key = useParams();
  const dispatch = useAppDispatch();
  const downloadLoading = useAppSelector(
    (state) => state.position.downloadTemplate.loading,
  );
  const importLoading = useAppSelector(
    (state) => state.position.importTemplate.loading,
  );
  const position = useAppSelector(
    (state) => state.position.positionOverview?.value,
  );

  async function handleSubmit(e?: React.FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    if (file && key.id) {
      dispatch(
        importPositionTemplate({
          positionId: key.id,
          file,
        }),
      ).then((res) => {
        if (close) {
          close();
        }
        if (refreshFunction && res.payload) {
          refreshFunction();
        }
      });
    } else {
      setInput({ ...input, error: true });
    }
  }
  const handleUploadFile = (file: File) => {
    setFile(file);
  };

  const handleDownloadTemplate = () => {
    if (!key.id) return;
    dispatch(downloadPositionTemplate({ positionId: key.id })).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload as any]));

      const link = document.createElement("a");
      link.href = url;
      link.download = position?.name
        ? `${position.name.toLowerCase().replace(/ /g, "-")}-template.csv`
        : "template-position.csv";

      document.body.appendChild(link);

      link.click();
      link.remove();
    });
  };

  return (
    <>
      <ModalWithContent
        close={() => (close ? close() : null)}
        title={"Import Candidates"}
        lightTitle={""}
        actionButton={{
          text: `Confirm`,
          onSubmit: () => handleSubmit(),
          processing: importLoading,
          disabled: importLoading || !file,
        }}
        modalClassName={"!w-[500px] max-h-[90%]"}
      >
        <div
          className={"flex flex-col gap-3 mt-5"}
          onClick={() => handleDownloadTemplate()}
        >
          <p className="text-sm text-z-grey-600 -mt-6 font-light zmd:text-center zsm:-mb-4">
            Download the following CSV template and fill it out with your
            candidates:
          </p>
          <div
            className={`hover:bg-[#d3fbcf] cursor-pointer rounded-[10px] p-[10px] bg-[#E0FFDD] 
                            w-full text-[#48A874] text-base flex justify-start items-center gap-2 ${downloadLoading ? "select-none opacity-80" : ""}`}
          >
            <CsvIco className={"[&_path]:fill-[#48A874] w-[25px]"} />
            Download CSV Template
            {downloadLoading ? (
              <Spinner classIcon={"w-[15px] h-[15px]"} />
            ) : null}
          </div>
          <hr className=" mt-3 mb-4 border-1 border-z-grey-200 w-full" />
        </div>
        <div className="flex flex-col gap-6 w-full">
          <div className="flex flex-col items-start">
            <p className="text-dark-gray text-sm font-light mb-3">
              Once you have the template filled out, drop it here:
            </p>
            <UploadDropzone
              accept={["csv"]}
              onChange={handleUploadFile}
              onRemove={() => setFile(null)}
              invalid={!file && input.error}
              placeholder={"Drop your CSV file here or "}
            />
          </div>
        </div>
      </ModalWithContent>
    </>
  );
};

export default ImportApplicantsForm;
