import { ReactComponent as CloseIco } from "assets/icons/close-ico.svg";
import CustomDropdown, {
  functionOptionType,
} from "../CustomDropdown/CustomDropdown";
import { ReactComponent as DropdownDots } from "assets/icons/dropdown-dots-icon.svg";
import { ReactNode } from "react";
import FullScreen from "../FullScreen/FullScreen";
interface propTypes {
  children: React.ReactNode;
  close?: () => void;
  className?: string;
  hideCloseIco?: boolean;
  dropdownOptions?: functionOptionType[];
  dropdownChildren?: ReactNode;
  enableFullScreen?: boolean;
}

// ModalHeader component that is inserted into the Modal component
const ModalHeader = ({
  children,
  close,
  className,
  hideCloseIco,
  dropdownOptions,
  dropdownChildren,
  enableFullScreen,
}: propTypes) => {
  return (
    <div
      className={`relative z-[10] bg-white border-b border-1 border-[#D6D6D6] w-full py-[8px] ${className ? className : ""} rounded-t-2xl`}
    >
      <div
        className={
          "flex justify-between items-start gap-3 w-full px-[20px] py-[8px]"
        }
      >
        {children}
        {dropdownOptions || !hideCloseIco ? (
          <div className={"flex items-center gap-[5px]"}>
            {dropdownOptions?.length ? (
              <CustomDropdown
                IconDropdown={DropdownDots}
                functionOption={dropdownOptions}
              />
            ) : dropdownChildren ? (
              dropdownChildren
            ) : null}
            {enableFullScreen ? <FullScreen /> : null}
            {!hideCloseIco ? (
              <div
                className={`w-[32px] h-[32px] flex justify-center items-center rounded-lg cursor-pointer hover:bg-z-grey-100 group`}
                onClick={close}
              >
                <CloseIco className="cursor-pointer  transition-all group-hover:[&_path]:fill-main-color [&_path]:fill-z-grey-500" />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ModalHeader;
