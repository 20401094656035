import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { testOverviewType } from "views/features/testSingle/testSingle.types";
import { errorRequestType } from "common/types/data.types";
import {
  getTestSimulationInfo,
  getTestSimulationQuestion,
  getTestSimulationQuestions,
  submitTestSimulationQuestionAnswer,
  submitQATestTimeout,
  submitQATest,
  getTestQuestionsMenuList,
  submitStatementScaleTest,
  submitStatementScaleTimeout,
  submitOpenQuestionsTest,
  submitOpenQuestionsTimeout,
} from "./testSimulation.actions";
import {
  sendRecordingStartNotification,
  sendRecordingStopNotification,
} from "./preinterviewTestSimulation.actions";
import { mimeTypeToFormatAndCodecs } from "./testSimulation.services";
import { stopStreaming } from "../common/testSImulation.functions";

interface initialStateTypes {
  testSimulationInfo: {
    value: testOverviewType | null;
    loading: boolean;
    refreshing: boolean;
    processing: boolean;
    isDemo: boolean;
    timerColor: string;
    error: errorRequestType;
  };
  questions: {
    value: Array<{ text: string; time: number } | any> | null;
    loading: boolean;
    error: errorRequestType;
  };
  questionsMenuList: {
    value: Array<{ text: string; time: number } | any> | null;
    loading: boolean;
    error: errorRequestType;
    isMenuOpen: boolean;
  };
  activeQuestion: {
    value: any;
    processing: boolean;
    loading: boolean;
    recording: boolean;
    isDemo: boolean;
    completed: boolean;
    error: errorRequestType;
    fetchNext: boolean;
  };
  preinterview: {
    stream: MediaStream | null;
    mimeType: {
      raw: string;
      type: string;
      format: string;
      codecs: string;
    };
    camera: {
      device: MediaDeviceInfo;
      label: string;
      value: string;
    } | null;
    mic: {
      device: MediaDeviceInfo;
      label: string;
      value: string;
    } | null;
  };
  isTestFinished: boolean;
  results: any[];
  answeredQuestions: string[];
}

const initialState: initialStateTypes = {
  testSimulationInfo: {
    value: null,
    loading: false,
    refreshing: false,
    processing: false,
    isDemo: false,
    timerColor: "bg-green",
    error: { isError: false, type: "", message: "" },
  },
  activeQuestion: {
    value: null,
    processing: false,
    recording: false,
    isDemo: false,
    completed: false,
    error: { isError: false, type: "", message: "" },
    loading: false,
    fetchNext: false,
  },
  questions: {
    value: null,
    loading: false,
    error: { isError: false, type: "", message: "" },
  },
  questionsMenuList: {
    value: null,
    loading: false,
    error: { isError: false, type: "", message: "" },
    isMenuOpen: false,
  },
  preinterview: {
    stream: null,
    mimeType: {
      raw: "",
      type: "",
      format: "",
      codecs: "",
    },
    camera: null,
    mic: null,
  },
  isTestFinished: false,
  results: [],
  answeredQuestions: [],
};

const testSimulation = createSlice({
  name: "testSimulation",
  initialState,
  reducers: {
    updatePreinterviewMimeType(state, { payload }) {
      const temp = mimeTypeToFormatAndCodecs(payload);
      state.preinterview.mimeType = temp;
    },
    updatePreinterviewStream(state, actions: PayloadAction<any>) {
      state.preinterview.stream = actions.payload;
    },
    updatePreinterviewCamera(state, actions: PayloadAction<any>) {
      state.preinterview.camera = actions.payload;
    },
    updatePreinterviewMic(state, actions: PayloadAction<any>) {
      state.preinterview.mic = actions.payload;
    },
    updateResults(state, actions: PayloadAction<any>) {
      state.results = [...state.results, actions.payload];
    },

    setIsTestDemo(state, { payload }) {
      state.testSimulationInfo.isDemo = payload;
    },
    setIsQuestionDemo(state, { payload }) {
      state.activeQuestion.isDemo = payload;
    },
    setIsMenuOpen(state, { payload }) {
      state.questionsMenuList.isMenuOpen = payload;
    },
    setActiveQuestionCompleted(state) {
      state.activeQuestion.completed = true;
    },
    setActiveQuestionFetchNext(state) {
      state.activeQuestion.fetchNext = true;
    },
    clearTestSimulation() {
      return initialState;
    },
    clearActiveQuestion(state) {
      state.activeQuestion = initialState.activeQuestion;
    },
    clearTestSimulationQuestions(state) {
      state.questions = initialState.questions;
      state.answeredQuestions = initialState.answeredQuestions;
    },
    clearPreinterviewStream(state) {
      if (state.preinterview.stream) {
        stopStreaming(state.preinterview.stream);
      }
      state.preinterview.stream = initialState.preinterview.stream;
    },

    clearTestSimulationApply(state) {
      // state.candidate = initialState.candidate;
      state.testSimulationInfo = initialState.testSimulationInfo;
    },
    setDemoAnsweredQuestion(state, { payload }) {
      state.answeredQuestions = payload;
    },
    setTimerColor(state, { payload }) {
      state.testSimulationInfo.timerColor = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTestSimulationInfo.pending, (state) => {
      state.testSimulationInfo.loading = true;
    });
    builder.addCase(getTestSimulationInfo.fulfilled, (state, { payload }) => {
      state.testSimulationInfo.value = payload;
      state.testSimulationInfo.loading = false;
    });
    builder.addCase(getTestSimulationInfo.rejected, (state, { payload }) => {
      state.testSimulationInfo.loading = false;
      state.testSimulationInfo.error = {
        isError: true,
        message: payload?.message ? payload.message : "",
        type: payload?.type ? payload.type : "",
      };
    });
    builder.addCase(getTestSimulationQuestions.pending, (state) => {
      state.questions.loading = true;
    });
    builder.addCase(
      getTestSimulationQuestions.fulfilled,
      (state, { payload }) => {
        state.questions.value = payload;
        state.questions.loading = false;
      }
    );
    builder.addCase(
      getTestSimulationQuestions.rejected,
      (state, { payload }) => {
        state.questions.loading = false;
        state.questions.error = {
          isError: true,
          message: payload?.message ? payload.message : "",
          type: payload?.type ? payload.type : "",
        };
      }
    );
    // builder.addCase(getTestQuestionsMenuList.pending, (state) => {
    //   state.questionsMenuList.loading = true;
    // });
    builder.addCase(
      getTestQuestionsMenuList.fulfilled,
      (state, { payload }) => {
        state.questionsMenuList.value = payload;
        state.questionsMenuList.loading = false;
      }
    );
    builder.addCase(getTestQuestionsMenuList.rejected, (state, { payload }) => {
      state.questionsMenuList.loading = false;
      state.questionsMenuList.error = {
        isError: true,
        message: payload?.message ? payload.message : "",
        type: payload?.type ? payload.type : "",
      };
    });
    builder.addCase(getTestSimulationQuestion.pending, (state) => {
      state.activeQuestion.loading = true;
      state.activeQuestion.completed = false;
      state.activeQuestion.fetchNext = false;
    });
    builder.addCase(
      getTestSimulationQuestion.fulfilled,
      (state, { payload }) => {
        state.activeQuestion.value = payload;
        state.activeQuestion.loading = false;
      }
    );
    builder.addCase(
      getTestSimulationQuestion.rejected,
      (state, { payload }) => {
        state.activeQuestion.loading = false;
        state.activeQuestion.error = {
          isError: true,
          message: payload?.message ? payload.message : "",
          type: payload?.type ? payload.type : "",
        };
      }
    );
    builder.addCase(
      sendRecordingStartNotification.fulfilled,
      (state, { payload }) => {
        // state.activeQuestion.value = payload;
        state.activeQuestion.recording = true;
      }
    );
    builder.addCase(sendRecordingStopNotification.pending, (state) => {
      state.activeQuestion.processing = true;
      state.activeQuestion.recording = false;
    });
    builder.addCase(
      sendRecordingStopNotification.fulfilled,
      (state, { payload }) => {
        // state.activeQuestion.value = payload;
        state.activeQuestion.processing = false;
        state.activeQuestion.completed = true;
      }
    );
    builder.addCase(
      sendRecordingStopNotification.rejected,
      (state, { payload }) => {
        state.activeQuestion.processing = false;
        state.activeQuestion.error = {
          isError: true,
          message: payload?.message ? payload.message : "",
          type: payload?.type ? payload.type : "",
        };
      }
    );
    builder.addCase(submitTestSimulationQuestionAnswer.pending, (state) => {
      state.activeQuestion.processing = true;
    });
    builder.addCase(
      submitTestSimulationQuestionAnswer.fulfilled,
      (state, { payload }) => {
        // state.activeQuestion.value = payload;
        state.activeQuestion.completed = true;
        state.activeQuestion.processing = false;
      }
    );
    builder.addCase(
      submitTestSimulationQuestionAnswer.rejected,
      (state, { payload }) => {
        state.activeQuestion.processing = false;
        state.activeQuestion.error = {
          isError: true,
          message: payload?.message ? payload.message : "",
          type: payload?.type ? payload.type : "",
        };
      }
    );
    builder.addCase(submitQATestTimeout.pending, (state) => {
      state.activeQuestion.processing = true;
    });
    builder.addCase(submitQATestTimeout.fulfilled, (state) => {
      state.activeQuestion.processing = false;
      state.isTestFinished = true;
    });
    builder.addCase(submitQATestTimeout.rejected, (state, { payload }) => {
      state.activeQuestion.processing = false;
      state.activeQuestion.error = {
        isError: true,
        message: payload?.message ? payload.message : "",
        type: payload?.type ? payload.type : "",
      };
    });
    builder.addCase(submitQATest.pending, (state) => {
      state.activeQuestion.processing = true;
    });
    builder.addCase(submitQATest.fulfilled, (state) => {
      state.activeQuestion.processing = false;
      state.isTestFinished = true;
    });
    builder.addCase(submitQATest.rejected, (state, { payload }) => {
      state.activeQuestion.processing = false;
      state.activeQuestion.error = {
        isError: true,
        message: payload?.message ? payload.message : "",
        type: payload?.type ? payload.type : "",
      };
    });
    builder.addCase(submitStatementScaleTest.pending, (state) => {
      state.activeQuestion.processing = true;
    });
    builder.addCase(submitStatementScaleTest.fulfilled, (state) => {
      state.activeQuestion.processing = false;
      state.isTestFinished = true;
    });
    builder.addCase(submitStatementScaleTest.rejected, (state, { payload }) => {
      state.activeQuestion.processing = false;
      state.activeQuestion.error = {
        isError: true,
        message: payload?.message ? payload.message : "",
        type: payload?.type ? payload.type : "",
      };
    });
    builder.addCase(submitStatementScaleTimeout.pending, (state) => {
      state.activeQuestion.processing = true;
    });
    builder.addCase(submitStatementScaleTimeout.fulfilled, (state) => {
      state.activeQuestion.processing = false;
      state.isTestFinished = true;
    });
    builder.addCase(
      submitStatementScaleTimeout.rejected,
      (state, { payload }) => {
        state.activeQuestion.processing = false;
        state.activeQuestion.error = {
          isError: true,
          message: payload?.message ? payload.message : "",
          type: payload?.type ? payload.type : "",
        };
      }
    );
    builder.addCase(submitOpenQuestionsTest.pending, (state) => {
      state.activeQuestion.processing = true;
    });
    builder.addCase(submitOpenQuestionsTest.fulfilled, (state) => {
      state.activeQuestion.processing = false;
      state.isTestFinished = true;
    });
    builder.addCase(submitOpenQuestionsTest.rejected, (state, { payload }) => {
      state.activeQuestion.processing = false;
      state.activeQuestion.error = {
        isError: true,
        message: payload?.message ? payload.message : "",
        type: payload?.type ? payload.type : "",
      };
    });
    builder.addCase(submitOpenQuestionsTimeout.pending, (state) => {
      state.activeQuestion.processing = true;
    });
    builder.addCase(submitOpenQuestionsTimeout.fulfilled, (state) => {
      state.activeQuestion.processing = false;
      state.isTestFinished = true;
    });
    builder.addCase(
      submitOpenQuestionsTimeout.rejected,
      (state, { payload }) => {
        state.activeQuestion.processing = false;
        state.activeQuestion.error = {
          isError: true,
          message: payload?.message ? payload.message : "",
          type: payload?.type ? payload.type : "",
        };
      }
    );
  },
});

export const {
  updatePreinterviewMic,
  updatePreinterviewCamera,
  updatePreinterviewStream,
  updatePreinterviewMimeType,
  setActiveQuestionCompleted,
  setActiveQuestionFetchNext,
  setIsTestDemo,
  setIsQuestionDemo,
  setIsMenuOpen,
  updateResults,
  clearTestSimulationQuestions,
  clearActiveQuestion,
  clearPreinterviewStream,
  clearTestSimulation,
  clearTestSimulationApply,
  setDemoAnsweredQuestion,
  setTimerColor,
} = testSimulation.actions;

export default testSimulation.reducer;
