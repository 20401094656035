// ** Redux Imports
// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import { http } from "common/api/http";
import { setToast } from "redux/alert";
import { fetchPosition, fetchPositionTemplate } from "./position.services";
import { RootState } from "redux/store";
import { errorRequestType } from "../../../../common/types/data.types";
import {
  updateEmailTemplateDesign,
  updatePositionHiringFlows,
} from "./position.reducer";
import { nanoid } from "nanoid";
import { singlePositionType } from "views/features/position/position.type";

export const getPositionOverview = createAsyncThunk<
  any,
  { id: string; positionTemplate?: boolean }
>(
  "position/getPositionOverview",
  async ({ id, positionTemplate }, { rejectWithValue }) => {
    try {
      const response = positionTemplate
        ? await fetchPositionTemplate(id)
        : await fetchPosition(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const refreshPositionOverview = createAsyncThunk(
  "position/refreshPositionOverview",
  async (
    { id, positionTemplate }: { id: string; positionTemplate?: boolean },
    { rejectWithValue },
  ) => {
    try {
      const response = positionTemplate
        ? await fetchPositionTemplate(id)
        : await fetchPosition(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const startHiringFlow = createAsyncThunk(
  "position/startHiringFlow",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.patch(`/positions/${id}/activate`);
      dispatch(
        setToast({
          message: "Position successfully activated.",
          type: "success",
        }),
      );
      dispatch(getPositionOverview({ id }));

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);
export const archiveHiringFlow = createAsyncThunk(
  "position/archiveHiringFlow",
  async (
    { id, type }: { id: string; type?: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.put(`/positions/${id}/archive`);
      dispatch(
        setToast({
          message: "Position successfully archived.",
          type: "success",
        }),
      );
      if (!type) {
        dispatch(getPositionOverview({ id }));
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const updateAssessmentModuleFlow = createAsyncThunk<
  any,
  {
    id: string;
    hiringFlowId: string;
    stepId: string;
    data: any;
    positionTemplate?: boolean;
  },
  { state: RootState }
>(
  "position/updateAssessmentModuleFlow",
  async (
    { id, hiringFlowId, stepId, data, positionTemplate },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const response: any = await http.put(
        `/positions${positionTemplate ? "/template" : ""}/${id}/hiring-flows/${hiringFlowId}/step/${stepId}`,
        data,
      );
      dispatch(
        setToast({
          message: `Successfully updated the ${positionTemplate ? "template" : "position"}.`,
          type: "success",
        }),
      );

      const positionHiringFlows = response?.data?.hiringFlows;

      if (positionHiringFlows) {
        dispatch(
          updatePositionHiringFlows({
            hiringFlows: response.data?.hiringFlows,
            canStart: response.data?.canStart,
            candidateApplicationOpenUntill:
              response.data?.candidateApplicationOpenUntill,
          }),
        );
      }

      return response.data?.hiringFlows[0].applicationStepModules.find(
        (item: { _id: string }) => item._id === stepId,
      );
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

type positionDataType = {
  id: string;
  name?: string;
  industry?: string;
  workingFrom?: string;
  hiringLimit?: number;
  employmentType?: string;
  location?: string;
  shouldSendReminders?: boolean;
  shouldSendSMSReminders?: boolean;
  microDims?: { microDimId: string; weight: number }[];
  automaticallyFollowCandidates?: boolean;
  sendAutomaticEmailsFromCompanyAddress?: boolean;
  description?: string;
  positionTemplate?: boolean;
};
export const updatePositionInfoData = createAsyncThunk(
  "testSingle/updatePositionInfoData",
  async (data: positionDataType, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.patch(
        `/positions${data?.positionTemplate ? "/template" : ""}/${data?.id}`,
        {
          data: JSON.stringify(data),
        },
      );
      dispatch(
        setToast({
          message: `Successfully updated the ${data?.positionTemplate ? "template" : "position"}.`,
          type: "success",
        }),
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

export const getPositionCandidateList = createAsyncThunk<
  any,
  {
    id: string;
    stepId: string;
    signal?: any;
    paramsArray?: any;
  }
>(
  "position/getCandidateList",
  async ({ id, stepId, paramsArray, signal }, { rejectWithValue }) => {
    try {
      const params = {
        limit: 50,
        page: paramsArray?.page ? paramsArray?.page : 1,
        ...(paramsArray?.search && {
          search: paramsArray?.search,
        }),
        ...(paramsArray?.sort && {
          sort: paramsArray?.sort,
        }),
        ...(paramsArray?.filters?.state?.length &&
          !paramsArray?.filters?.state?.includes("all") && {
            state: `${paramsArray?.filters?.state.map((item: string) =>
              item.toUpperCase(),
            )}`,
          }),
        ...(paramsArray?.filters?.from_date && {
          enteredAtFrom: paramsArray?.filters?.from_date,
        }),
        ...(paramsArray?.filters?.to_date && {
          enteredAtTo: paramsArray?.filters?.to_date,
        }),
        ...((paramsArray?.filters?.followerIds === "notFollowed" ||
          paramsArray?.filters?.followerIds?.length) && {
          followerIds:
            paramsArray?.filters?.followerIds === "notFollowed"
              ? "null"
              : `${paramsArray?.filters?.followerIds.map((item: string) => {
                  return item;
                })}`,
        }),
        ...(paramsArray?.filters?.scoreType && {
          [paramsArray?.filters?.scoreType]:
            paramsArray?.filters?.scoreType === "threshold"
              ? Number(paramsArray?.filters?.scoreValue) / 100
              : paramsArray?.filters?.scoreValue,
        }),
        ...(paramsArray?.filters?.origin && {
          origin: paramsArray?.filters.origin,
        }),
      };

      const response = await http.get(
        `/positions/${id}/step/${Number(stepId)}/progress`,
        { params, signal },
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const exportPositionCandidateList = createAsyncThunk<
  any,
  {
    id: string;
    stepId: string;
    type: string;
    paramsArray?: any;
    applicationIds?: string[];
  },
  { state: RootState }
>(
  "position/exportCandidateList",
  async (
    { id, stepId, type, paramsArray, applicationIds },
    { rejectWithValue },
  ) => {
    try {
      const params = {
        ...(paramsArray?.search && {
          search: paramsArray?.search,
        }),
        ...(paramsArray?.filters?.state?.length && {
          state: `${paramsArray?.filters?.state.map((item: string) =>
            item.toUpperCase(),
          )}`,
        }),
        ...(paramsArray?.filters?.from_date && {
          enteredAtFrom: paramsArray?.filters?.from_date,
        }),
        ...(paramsArray?.filters?.to_date && {
          enteredAtTo: paramsArray?.filters?.to_date,
        }),
        ...(paramsArray?.filters?.score?.type === "threshold" && {
          threshold: paramsArray?.filters?.score?.score / 100,
        }),
        ...(applicationIds && {
          ids: applicationIds,
        }),
      };
      const response = await http.get(
        `/positions/${id}/step/${Number(stepId)}/progress/export`,
        type === "filtered" || type === "bulkCsvReports" ? { params } : {},
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const exportPdfReportsCandidateList = createAsyncThunk<
  any,
  {
    id: string;
    stepId: string;
    type: string;
    applicationIds?: string[];
    paramsArray?: any;
  },
  { state: RootState }
>(
  "position/exportPdfReportsCandidateList",
  async (
    { id, stepId, type, paramsArray, applicationIds },
    { rejectWithValue },
  ) => {
    try {
      const params = {
        ...(paramsArray?.search && {
          search: paramsArray?.search,
        }),
        ...(paramsArray?.filters?.state?.length && {
          state: `${paramsArray?.filters?.state.map((item: string) =>
            item.toUpperCase(),
          )}`,
        }),
        ...(paramsArray?.filters?.from_date && {
          enteredAtFrom: paramsArray?.filters?.from_date,
        }),

        ...(paramsArray?.filters?.to_date && {
          enteredAtTo: paramsArray?.filters?.to_date,
        }),
        ...(paramsArray?.filters?.score?.type === "threshold" && {
          threshold: paramsArray?.filters?.score?.score / 100,
        }),
        ...(applicationIds &&
          type === "bulkReports" && {
            ids: applicationIds,
          }),
      };

      const response: any = await http.get(
        `/positions/${id}/step/${Number(stepId)}/progress/export/zip`,
        type === "filtered" || type === "bulkReports" ? { params } : {},
      );

      if (response.data.url) {
        const nanoId = nanoid();

        const link = document.createElement("a");
        link.href = response.data.url;
        link.download = `${nanoId}-reports.zip`;

        document.body.appendChild(link);

        link.click();
        link.remove();
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const refreshPositionCandidateList = createAsyncThunk<
  any,
  { id: string; stepId: string; paramsArray?: any },
  { state: RootState }
>(
  "position/refreshCandidateList",
  async ({ id, stepId, paramsArray }, { getState, rejectWithValue }) => {
    try {
      const params = {
        limit: 50,
        page: paramsArray?.page ? paramsArray?.page : 1,
        ...(paramsArray?.search && {
          search: paramsArray?.search,
        }),
        ...(paramsArray?.sort && {
          sort: paramsArray?.sort,
        }),
        ...(paramsArray?.filters?.state?.length &&
          !paramsArray?.filters?.state?.includes("all") && {
            state: `${paramsArray?.filters?.state.map((item: string) =>
              item.toUpperCase(),
            )}`,
          }),
        ...(paramsArray?.filters?.from_date && {
          enteredAtFrom: paramsArray?.filters?.from_date,
        }),
        ...(paramsArray?.filters?.to_date && {
          enteredAtTo: paramsArray?.filters?.to_date,
        }),
        ...((paramsArray?.filters?.followerIds === "notFollowed" ||
          paramsArray?.filters?.followerIds?.length) && {
          followerIds:
            paramsArray?.filters?.followerIds === "notFollowed"
              ? "null"
              : `${paramsArray?.filters?.followerIds.map((item: string) => {
                  return item;
                })}`,
        }),
        ...(paramsArray?.filters?.score && {
          [paramsArray?.filters?.score.type]:
            paramsArray?.filters?.score?.type === "threshold"
              ? Number(paramsArray?.filters?.score?.score) / 100
              : paramsArray?.filters?.score?.score,
        }),
      };

      const response = await await http.get(
        `/positions/${id}/step/${Number(stepId)}/progress`,
        { params },
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const positionActionsCandidate = createAsyncThunk<
  any,
  {
    positionId: string;
    stepId: string;
    data: string[];
    type: string;
  },
  {
    state: RootState;
  }
>(
  "position/positionActionsCandidate",
  async (
    { positionId, stepId, data, type },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const response: { data: { moduleState: string; _id: string }[] } =
        await http.put(
          `/positions/${positionId}/step/${Number(stepId)}/${type}`,
          {
            applicationIds: data,
          },
        );

      const values =
        getState().position.positionCandidateList?.value?.paginatedData?.data;
      const updatedData = values.map((applicant: { applicationId: string }) => {
        const updateInfo = response?.data.find(
          (update: { _id: string; moduleState: string }) =>
            update._id === applicant.applicationId,
        );

        if (updateInfo) {
          return { ...applicant, state: updateInfo.moduleState };
        }

        return applicant;
      });

      dispatch(refreshPositionOverview({ id: positionId }));

      dispatch(
        setToast({
          message: `Successfully ${type !== "hire" ? `${type}ed` : "hired"}`,
          type: "success",
        }),
      );

      return updatedData;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const retryApplicantAssessment = createAsyncThunk<
  any,
  {
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
  },
  {
    state: RootState;
  }
>(
  "position/retryApplicantAssessment",
  async (
    { applicationId, stepId, hiringFlowId },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const response: { data: { stepState: string; overallScore: string } } =
        await http.get(
          `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/restart`,
        );
      dispatch(
        setToast({
          message: `Successfully retried an applicant.`,
          type: "success",
        }),
      );
      const values =
        getState().position.positionCandidateList?.value?.paginatedData?.data;
      const updatedData = values.map((applicant: { applicationId: string }) => {
        if (applicant.applicationId === applicationId) {
          return {
            ...applicant,
            state: response?.data?.stepState,
            results: null,
            stepScore: null,
            overallScore: response?.data?.overallScore,
          };
        }

        return applicant;
      });

      return updatedData;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const createNewPositionModule = createAsyncThunk<
  any,
  {
    id: string;
    hiringFlowId: string;
    data: { moduleType: string; stepIndex: number };
    positionTemplate?: boolean;
  },
  { state: RootState }
>(
  "position/createNewModule",
  async (
    { id, hiringFlowId, data, positionTemplate },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.post(
        `/positions${positionTemplate ? "/template" : ""}/${id}/hiring-flows/${hiringFlowId}/step`,
        data,
      );
      dispatch(
        refreshPositionOverview({ id, positionTemplate: positionTemplate }),
      );
      dispatch(
        setToast({
          message: "Successfully created a new step",
          type: "success",
        }),
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

export const positionDeleteStep = createAsyncThunk<
  any,
  {
    positionId: string;
    hiringFlowId: string;
    stepId: string;
    positionTemplate?: boolean;
  },
  { state: RootState }
>(
  "position/deleteStep",
  async (
    { positionId, hiringFlowId, stepId, positionTemplate },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.delete(
        `/positions${positionTemplate ? "/template" : ""}/${positionId}/hiring-flows/${hiringFlowId}/step/${stepId}`,
      );

      dispatch(
        setToast({
          message: "Successfully deleted step",
          type: "success",
        }),
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const duplicatePosition = createAsyncThunk<
  any,
  {
    id: string;
    disableRefreshPosition?: boolean;
    navigate?: Function;
  },
  { state: RootState }
>(
  "position/duplicatePosition",
  async (
    { id, disableRefreshPosition, navigate },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.post(`/positions/${id}/duplicate`);
      dispatch(
        setToast({
          message:
            "You have successfully created a duplicate of this position.",
          type: "success",
        }),
      );

      if (!disableRefreshPosition) {
        dispatch(refreshPositionOverview({ id: response.data._id }));
      }
      console.log(
        `${response?.data?.companyId}/position/${response.data._id}`,
        "a",
      );
      if (navigate) {
        navigate(`/${response?.data?.companyId}/position/${response.data._id}`);
      }

      return { id: response.data._id, companyId: response?.data?.companyId };
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const downloadPositionTemplate = createAsyncThunk(
  "position/downloadTemplate",
  async (
    { positionId }: { positionId: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.get(`/positions/csv-template/${positionId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

export const importPositionTemplate = createAsyncThunk<
  any,
  {
    positionId: string;
    file: File;
  },
  {
    state: RootState;
  }
>(
  "position/importTemplate",
  async ({ positionId, file }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await http.post(
        `/application/position/${positionId}/import-csv`,
        { file },
        { headers: { "Content-Type": "multipart/form-data" } },
      );
      dispatch(
        setToast({
          message: "Successfully imported candidates",
          type: "success",
        }),
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const getPositionEmailTemplate = createAsyncThunk<
  any,
  { positionId: string; emailType: string; positionTemplate?: boolean },
  { state: RootState }
>(
  "position/getPositionEmailTemplate",
  async ({ positionId, emailType, positionTemplate }, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `/positions${positionTemplate ? "/template" : ""}/${positionId}/email-template/${emailType}`,
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const savePositionEmailTemplate = createAsyncThunk<
  any,
  {
    positionId: string;
    emailType: string;
    body: { html: string; json: any };
    positionTemplate?: boolean;
  },
  { state: RootState }
>(
  "position/savePositionEmailTemplate",
  async (
    { positionId, emailType, body, positionTemplate },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.put(
        `/positions${positionTemplate ? "/template" : ""}/${positionId}/email-template/${emailType}`,
        body,
      );

      dispatch(updateEmailTemplateDesign(body));

      dispatch(
        setToast({
          message: `Successfully updated email template`,
          type: "success",
        }),
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const getPositionStepEmailTemplate = createAsyncThunk<
  any,
  {
    positionId: string;
    hiringFlowsId: string;
    stepId: string;
    emailType: string;
    positionTemplate?: boolean;
  },
  { state: RootState }
>(
  "position/getPositionStepEmailTemplate",
  async (
    { positionId, hiringFlowsId, stepId, emailType, positionTemplate },
    { rejectWithValue },
  ) => {
    try {
      const response = await http.get(
        `positions${positionTemplate ? "/template" : ""}/${positionId}/hiring-flows/${hiringFlowsId}/step/${stepId}/email-template/${emailType}`,
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const savePositionStepEmailTemplate = createAsyncThunk<
  any,
  {
    positionId: string;
    hiringFlowsId: string;
    stepId: string;
    emailType: string;
    body: { html: string; json: any };
    positionTemplate?: boolean;
  },
  { state: RootState }
>(
  "position/savePositionStepEmailTemplate",
  async (
    { positionId, hiringFlowsId, stepId, emailType, body, positionTemplate },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.put(
        `positions${positionTemplate ? "/template" : ""}/${positionId}/hiring-flows/${hiringFlowsId}/step/${stepId}/email-template/${emailType}`,
        body,
      );

      dispatch(
        setToast({
          message: `Successfully updated email template`,
          type: "success",
        }),
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const downloadSingleReport = createAsyncThunk<
  any,
  { applicantId: string; applicationId: string; fileName?: string | null },
  { state: RootState }
>(
  "position/downloadSingleReport",
  async ({ applicantId, applicationId, fileName }, { rejectWithValue }) => {
    try {
      const response: any = await http.get(
        `/applicant/${applicantId}/application/${applicationId}/pdf`,
      );

      const link = document.createElement("a");
      link.href = response.data.url;
      link.download = `${fileName || applicationId}-report.pdf`;

      document.body.appendChild(link);

      link.setAttribute("target", "_blank");
      link.click();
      link.remove();

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const updatePositionSources = createAsyncThunk(
  "position/updatePositionSources",
  async (
    { data, id }: { data: { name: string }[]; id: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.post(`/positions/${id}/source`, data);
      dispatch(
        setToast({
          message: "Successfully added a new source",
          type: "success",
        }),
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

export const deletePositionSource = createAsyncThunk<
  any,
  { value: string; id: string },
  { state: RootState }
>(
  "position/deletePositionSource",
  async ({ value, id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await http.delete(`/positions/${id}/source`, {
        data: { name: value },
      });
      dispatch(
        setToast({
          message: "Successfully deleted a source.",
          type: "success",
        }),
      );

      const values = getState().position.positionOverview?.value?.sources;
      return values.filter((item: { name: string }) => item.name !== value);
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

export const getPositionStats = createAsyncThunk(
  "position/positionStats",
  async (
    { positionId }: { positionId: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.get(`/positions/${positionId}/stats`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const updatePositionGeneralSettings = createAsyncThunk(
  "position/updatePositionGeneralSettings",
  async (
    {
      data,
      positionId,
      positionTemplate,
    }: {
      data: singlePositionType;
      positionId: string;
      positionTemplate?: boolean;
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.patch(
        `/positions${positionTemplate ? "/template" : ""}/${positionId}`,
        {
          data: JSON.stringify(data),
        },
      );
      dispatch(
        setToast({
          message: `Successfully updated the ${positionTemplate ? "template" : "position"}.`,
          type: "success",
        }),
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

export const getJobDescriptionAI = createAsyncThunk<
  any,
  { id: string; positionTemplate?: boolean },
  {
    rejectValue: errorRequestType;
  }
>(
  "position/jobDescriptionAI",
  async ({ id, positionTemplate }, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `/positions${positionTemplate ? "/template" : ""}/${id}/generate-job-description`,
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

export const getPositionSurvey = createAsyncThunk<
  any,
  {
    paramsArray?: any;
    stepId?: number;
    positionId: string;
    signal?: any;
  },
  { state: RootState }
>(
  "position/surveyResults",
  async ({ paramsArray, positionId, stepId, signal }, { rejectWithValue }) => {
    try {
      const params = {
        limit: 50,
        page: paramsArray?.page ? paramsArray?.page : 1,
        ...(paramsArray?.filters?.company && {
          companyId: paramsArray?.filters.company.value,
        }),
        ...(paramsArray?.filters?.position && {
          positionIds: paramsArray?.filters.position.value,
        }),
        ...(paramsArray?.filters?.dateFrom && {
          dateFrom: paramsArray?.filters.dateFrom,
        }),
        ...(paramsArray?.filters?.dateTo && {
          dateTo: paramsArray?.filters.dateTo,
        }),
        ...(paramsArray?.search && {
          search: paramsArray?.search,
        }),
        ...(paramsArray?.sort && {
          sort: paramsArray?.sort,
        }),
        positionIds: positionId,
        ...(stepId && {
          step: stepId,
        }),
      };

      const response = await http.get(`/application/survey/results`, {
        params,
        signal,
      });

      if (!response?.data) return;
      return response?.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const exportPositionSurvey = createAsyncThunk<
  any,
  {
    paramsArray?: any;
    signal?: any;
    stepId?: number;
    positionId: string;
    type?: string;
  },
  { state: RootState }
>(
  "position/exportSurveyResults",
  async (
    { paramsArray, signal, positionId, stepId, type },
    { rejectWithValue },
  ) => {
    try {
      const params = {
        limit: 50,
        page: paramsArray?.page ? paramsArray?.page : 1,
        ...(paramsArray?.filters?.company && {
          companyId: paramsArray?.filters.company.value,
        }),
        ...(paramsArray?.filters?.position && {
          positionIds: paramsArray?.filters.position.value,
        }),
        ...(paramsArray?.filters?.dateFrom && {
          dateFrom: paramsArray?.filters.dateFrom,
        }),
        ...(paramsArray?.filters?.dateTo && {
          dateTo: paramsArray?.filters.dateTo,
        }),
        ...(paramsArray?.search && {
          search: paramsArray?.search,
        }),
        ...(paramsArray?.sort && {
          sort: paramsArray?.sort,
        }),
        positionIds: positionId,
        ...(stepId && {
          step: stepId,
        }),
      };

      const response = await http.get(`/application/survey/results/export`, {
        params,
      });

      if (!response?.data) return;
      return response?.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const updatePositionJobStreetIntegration = createAsyncThunk(
  "position/updateJobStreet",
  async (data: { id: string; type: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.patch(
        `/positions/${data?.id}/integration/job-street/${data.type}`,
      );
      dispatch(
        setToast({
          message: `Successfully ${data.type}d Seek integration`,
          type: "success",
        }),
      );
      dispatch(refreshPositionOverview({ id: data.id }));
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

export const getResumesHistory = createAsyncThunk<
  any,
  {
    positionId: string;
    page?: number;
    limit?: number;
  },
  { state: RootState }
>(
  "position/importedResumes",
  async ({ page, limit, positionId }, { rejectWithValue }) => {
    try {
      const params = {
        limit: 50,
        page: page ? page : 1,
      };

      const response = await http.get(
        `/application/position/${positionId}/import-pdf`,
        {
          params,
        },
      );

      if (!response?.data) return;
      return response?.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code || "",
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const importPositionResumes = createAsyncThunk<
  any,
  {
    positionId: string;
    files: any;
    source?: string;
  },
  {
    state: RootState;
    rejectValue: errorRequestType;
  }
>(
  "position/importResumes",
  async ({ positionId, files, source }, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.post(
        `/application/position/${positionId}/import-pdf`,
        {
          ...(source && {
            source: source,
          }),
          files,
        },
        { headers: { "Content-Type": "multipart/form-data" } },
      );
      dispatch(
        setToast({
          message: "Successfully imported resumes",
          type: "success",
        }),
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

// POSITION FOLLOW
export const followUnfollowPosition = createAsyncThunk<
  any,
  {
    positionId: string;
    typeAction: "follow" | "unfollow";
    skipAutomaticFollow?: boolean;
    successMessage?: string;
  }
>(
  "applicant/followApplication",
  async (
    { positionId, typeAction, skipAutomaticFollow, successMessage },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await http.post(
        `positions/${positionId}/${typeAction}`,
        {
          skipAutomaticFollow: skipAutomaticFollow || false,
        },
      );

      dispatch(
        setToast({
          message: successMessage
            ? successMessage
            : `Successfully ${typeAction}ed position.`,
          type: "success",
        }),
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

// POSITION TEMPLATE
export const positionSaveAsTemplate = createAsyncThunk<
  any,
  { id: string; data: { name: string; description?: string } }
>(
  "position/saveAsTemplate",
  async ({ id, data }, { dispatch, getState, rejectWithValue }) => {
    try {
      const dataArray = {
        ...(data?.name && {
          name: data.name,
        }),
        ...(data?.description && {
          description: data.description,
        }),
      };
      const response: any = await http.post(
        `/positions/${id}/template/save`,
        dataArray,
      );
      dispatch(
        setToast({
          message: "Successfully saved the position template",
          type: "success",
        }),
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

// DELETE POSITION
export const deletePosition = createAsyncThunk<
  any,
  {
    positionId: string;
  },
  { state: RootState }
>(
  "position/deletePosition",
  async ({ positionId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.delete(`/positions/${positionId}`);

      dispatch(
        setToast({
          message: "Successfully deleted position",
          type: "success",
        }),
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);
